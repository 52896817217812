import React, { Component } from 'react'

import Layout from '@components/layout/Layout'
import ReferenceComponent from '@components/references/reference.component'
import SiteLink from '@components/SiteLink'
import { references } from '@components/references/clinical-interview-references'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './clinical-interview.scss'

class ClinicalInterview extends Component {
  render() {
    return (
      <Layout addedClass='page-clinical-interview' location={this.props.location}>
        <div>
          <div className="clinical-interview-page-header">
            <div className="primary-container">
              <div className="clinical-interview-header-text">
                <h1>Assessing Symptoms in the Clinical Interview</h1>
                <p>
                  A complete clinical interview that assesses all 5 main narcolepsy symptoms is important for the differential diagnosis and provides clinical context when evaluating the results of sleep laboratory testing.<sup>1-3</sup> For pediatric patients, including a caregiver in the clinical interview can be helpful to corroborate or refute the patient's report of sleepiness or to uncover cataplexy, especially in the case of younger patients who may not be able to describe their own symptoms.<sup>4,5</sup>
                </p>
              </div>
            </div>
            <div
              className="clinical-interview-image-container"
              role="image"
              aria-label="clinical interview : narcolepsy diagnosis image 2"
            />
          </div>
          <div className="section-gradient" />
          <div className="primary-container">
            <div className="clinical-interview-bullet-list">
              <h2>Clinical History and Symptom Assessment</h2>
              <ul className="top-level-ul">
                <li>
                  <span>
                    Obtain a detailed clinical history of associated symptoms.<sup>4-6</sup>
                    <ul className="dashed-seperate">
                      <li>
                        <span>Caregivers may be the primary source of information for younger children.</span>
                      </li>
                      <li>
                        <span>
                          Older children and adolescents should be questioned directly about their symptoms; caregivers
                          may help provide additional insight and observation.
                        </span>
                      </li>
                    </ul>
                  </span>
                </li>
                <li>
                  <span>
                    Identify complaints of excessive daytime sleepiness.
                    <ul className="dashed-seperate">
                      <li>
                        <span>
                          Children and adolescents may use terms such as “tiredness” or “lack of energy” to describe
                          sleepiness.
                        </span>
                      </li>
                      <li>
                        <span>
                          Caregivers or teachers may report hyperactivity, poor concentration, and “bad behavior.”
                        </span>
                      </li>
                    </ul>
                  </span>
                </li>
              </ul>
            </div>
            <div className="questions-to-ask-card">
              <h2>Questions to Ask During the Clinical Interview</h2>
              <h3>
                To Assess Excessive Daytime Sleepiness, Ask Patients and Caregivers<sup>6,7</sup>:
              </h3>
              <ul className="top-level-ul">
                <li>
                  <span>Is your child very difficult to wake up in the morning?</span>
                </li>
                <li>
                  <span>Is your child constantly tired or sleepy during the day?</span>
                </li>
                <li>
                  <span>Does your child fall asleep during school or other activities?</span>
                </li>
                <li>
                  <span>
                    Does your child take a nap during the day? If so, how long does it last and is it refreshing?
                  </span>
                </li>
                <li>
                  <span>
                    Does your child exhibit hyperactivity, poor concentration/cognitive problems, or “bad behavior”?
                  </span>
                </li>
              </ul>
              <div className="light-header">
                To identify sleep habits, ask caregivers<sup>6</sup>:
              </div>
              <ul className="top-level-ul">
                <li>
                  <span>What time does your child go to bed and wake up on school days and nonschool days?</span>
                </li>
                <li>
                  <span>How long does it take your child to fall asleep?</span>
                </li>
                <li>
                  <span>How many times does your child wake up during the night? For how long?</span>
                </li>
              </ul>
              <p className="comment-normal">
                Use the{' '}
                <SiteLink
                  to="/pediatric/recognize/epworth-sleepiness-scale-for-children-and-adolescents/"
                  className="inline-link"
                  tracking="pediatric clinical interview, click, ess chad- clinical interview"
                >
                  Epworth Sleepiness Scale for Children and Adolescents (ESS-CHAD)
                </SiteLink>{' '}
                with patients and their caregivers to identify excessive daytime sleepiness.
              </p>
              <p className="comment-light">
                The ESS-CHAD is not intended to make a narcolepsy diagnosis or replace complete evaluation by a sleep
                specialist.
              </p>
              <hr />
              <h3>
                To Assess Cataplexy, Ask Patients and Caregivers<sup>8,9</sup>:
              </h3>
              <ul className="top-level-ul">
                <li>
                  <span>
                    Has your child ever reported feeling sudden weakness in their legs, arms, head, or face when having
                    fun or feeling excited, angry, or laughing?
                  </span>
                </li>
                <li>
                  <span>How often have you noticed the following in your child:</span>
                </li>
                <ul className="dashed-seperate">
                  <li>
                    <span>Falling down?</span>
                  </li>
                  <li>
                    <span>Head dropping?</span>
                  </li>
                  <li>
                    <span>Tongue sticking out, mouth opening, eyelids drooping, eyes closing, or eyes rolling?</span>
                  </li>
                </ul>
              </ul>
              <p className="comment-normal">
                Patients and caregivers can use the{' '}
                <SiteLink
                  to="/resources/resource-center/"
                  anchor="#resource-kit-itemSleep/Cataplexy Diary"
                  className="inline-link"
                  tracking="pediatric clinical interview, click, sleep cataplexy diary resources page - clinical interview"
                >
                  Sleep/Cataplexy Diary
                </SiteLink>{' '}
                to identify possible events and discuss them with a sleep specialist.
              </p>
              <p className="comment-light">
                The Sleep/Cataplexy Diary is not intended to make a narcolepsy diagnosis or replace complete evaluation by
                a sleep specialist.
              </p>
              <hr />
              <h3>
                To Assess Hallucinations, Ask Patients and Caregivers<sup>1,6,7,10</sup>:
              </h3>
              <ul className="top-level-ul">
                <li>
                  <span>
                    How often does your child have dreamlike experiences or see or hear things that are not really
                    there when falling asleep or waking up?
                  </span>
                </li>
              </ul>
              <hr />
              <h3>
                To Assess Sleep Paralysis, Ask Patients and Caregivers<sup>4</sup>:
              </h3>
              <ul className="top-level-ul">
                <li>
                  <span>How often does your child feel unable to move or speak when falling asleep or waking up?</span>
                </li>
              </ul>
              <hr />
              <h3>
                To Assess Sleep Disruption, Ask Patients and Caregivers<sup>6,7</sup>:
              </h3>
              <ul className="top-level-ul">
                <li>
                  <span>Does your child still take naps? </span>
                </li>
                <li>
                  <span>Is your child very difficult to wake up in the morning?</span>
                </li>
                <li>
                  <span>How often does your child wake up during the night? For how long?
                    <ul className="dashed-seperate">
                      <li>
                        <span>Does your child have trouble getting back to sleep after waking up during the night?</span>
                      </li>
                    </ul>
                  </span>
                </li>
              </ul>
            </div>
            <SiteLink 
              className="cta-tile-button btn-primary"
              to="/pediatric-patients/differential-diagnosis/"
              tracking="pediatric clinical interview, click, differential diagnosis - clinical interview"
              liveRampTracking={true}
            >
              <div className="cta-tile-button-text">
                Read Next: <span style={{ textTransform: 'none', fontWeight: 'normal' }}>Differential Diagnosis</span>&nbsp;&nbsp;
              </div>
              <FontAwesomeIcon className="arrow-right" icon="angle-right" />
            </SiteLink>
          </div>
        </div>
        <ReferenceComponent referenceList={references} />
      </Layout>
    )
  }
}

export default ClinicalInterview
