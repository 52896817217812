export const references = [
  {
    content: 'American Academy of Sleep Medicine. Central disorders of hypersomnolence. In:',
    source: 'The International Classification of Sleep Disorders – Third Edition (ICSD-3) Online Version.',
    info: 'Darien, IL: American Academy of Sleep Medicine; 2014.'
  },
  {
    content:
      'Littner MR, Kushida C, Wise M, et al.; Standards of Practice Committee of the American Academy of Sleep Medicine. Practice parameters for clinical use of the multiple sleep latency test and the maintenance of wakefulness test.',
    source: 'Sleep.',
    info: '2005;28(1):113-121.'
  },
  {
    content: 'Pelayo R, Lopes MC. Narcolepsy. In: Lee-Chiong TL, ed.',
    source: 'Sleep: A Comprehensive Handbook.',
    info: 'Hoboken, NJ: Wiley and Sons, Inc.; 2006:145-149.'
  },
  {
    content: 'Babiker MOE, Prasad M. Narcolepsy in children: a diagnostic and management approach.',
    source: 'Pediatr Neurol.',
    info: '2015;52(6):557-565. '
  },
  {
    content: 'Guilleminault C, Brooks SN. Excessive daytime sleepiness: a challenge for the practicing neurologist. ',
    source: ' Brain.',
    info: ' 2001;124(Pt 8):1482-1491.'
  },
  {
    content: 'Luginbuehl M, Kohler WC. Screening and evaluation of sleep disorders in children and adolescents. ',
    source: 'Child Adolesc Psychiatric Clin N Am.',
    info: '2009;18(4):825-838.'
  },
  {
    content: 'Nevsimalova S. The diagnosis and treatment of pediatric narcolepsy.',
    source: 'Curr Neurol Neurosci Rep.',
    info: '2014;14(8):469.'
  },
  {
    content:
      'Benmedjahed K, Wang YG, Lambert J, et al. Assessing sleepiness and cataplexy in children and adolescents with narcolepsy: a review of current patient-reported measures. ',
    source: 'Sleep Med.',
    info: '2017;32:143-149.'
  },
  {
    content:
      'Wang YG, Benmedjahed K, Lambert J, et al. Assessing narcolepsy with cataplexy in children and adolescents: development of a cataplexy diary and the ESS-CHAD.',
    source: 'Nat Sci Sleep.',
    info: '2017;9:201-211. '
  },
  {
    content: 'Nevsimalova S. Narcolepsy in childhood. ',
    source: 'Sleep Med Rev. ',
    info: '2009;13(2):169-180. '
  },
]